import { useGetUser } from '@nx/api'
import { openLogin } from '@nx/fire/login-modal'

import {
  EFollowType,
  FollowItemProps,
  FollowType,
} from '../get-user-follows.types'
import { useFollowItem } from '../useFollowItem'
import {
  UseGetAllFollowedResponse,
  useGetAllFollowed,
} from '../useGetAllFollowed'
import { useUnfollowItem } from '../useUnfollowItem'

interface FollowHookProps {
  type: EFollowType
  itemId: number | string
  metadata?: FollowItemProps['metadata']
  baseURL: string
}

function getResponseForType(
  followedItems: UseGetAllFollowedResponse,
  type: EFollowType
) {
  switch (type) {
    case FollowType.artist:
      return followedItems.artists
    case FollowType.auction:
      return followedItems.auctions
    case FollowType.department:
      return followedItems.departments
    default:
      return {
        ...followedItems.lots,
        data: followedItems.lots.data
          ? (Object.values(followedItems.lots.data).flat() as number[])
          : [],
      }
  }
}

function isNumber(itemId: string | number, type: FollowType): itemId is number {
  return [FollowType.artist, FollowType.lot, FollowType.auction].includes(type)
}

export const useFollow = ({
  type,
  itemId,
  metadata,
  baseURL,
}: FollowHookProps) => {
  const { data: user } = useGetUser(baseURL)

  const followedItems = useGetAllFollowed({
    userId: user?.customerId,
    baseUrl: baseURL,
  })
  const followItem = useFollowItem(baseURL)
  const unfollowItem = useUnfollowItem(baseURL)

  const followedItemsByType = getResponseForType(followedItems, type)

  const isFollowing =
    followedItemsByType?.data instanceof Array
      ? isNumber(itemId, type as FollowType)
        ? (followedItemsByType.data as number[])?.includes(itemId)
        : (followedItemsByType.data as string[])?.includes(itemId)
      : false

  function toggleFollow(e: React.SyntheticEvent) {
    if (!user) {
      e.preventDefault()
      openLogin()
    } else {
      if (isFollowing) {
        unfollowItem.mutate({ userId: user.customerId, type, itemId, metadata })
      } else {
        followItem.mutate({ userId: user.customerId, type, itemId, metadata })
      }
    }
  }

  return {
    toggleFollow,
    isFollowing,
    isLoading: followItem.isPending || unfollowItem.isPending,
  }
}
